.bodyPhotos {
  font-family: "Open Sans", Arial, sans-serif;
  min-height: 100vh;
  color: #262626;
  font-size: 10px;
}

.containerPhotos {
  max-width: 100%;
  margin: 0 5%;
}

.btn {
  display: inline-block;
  font: inherit;
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  cursor: pointer;
}

.btn:focus {
  outline: 0.5rem auto #4d90fe;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/* Profile Section */

.profilePhotos {
  padding: 5rem 0;
}

.profilePhotos::after {
  content: "";
  display: block;
  clear: both;
}

.thumImg {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 0px 6px 2px rgba(117, 117, 117, 1);
}

.profile-image {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3rem;
}

.profile-user-settings,
.profile-stats,
.profile-bio {
  float: left;
  width: calc(66.666% - 2rem);
}

.profile-user-settings {
  margin-top: 1.1rem;
}

.profile-user-name {
  display: inline-block;
  font-size: 2.5rem;
  font-weight: 300;
}

.profile-edit-btn {
  font-size: 1.4rem;
  line-height: 1.8;
  border: 0.1rem solid #dbdbdb;
  border-radius: 0.3rem;
  padding: 0 2.4rem;
  margin-left: 2rem;
}

.profile-edit-btn2 {
  border: none;
  background-color: unset;
  color: white;
  font-size: 5rem;
  position: fixed;
  z-index: 99;
  top: 2rem;
  right: 2rem;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
}

.profile-bio {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 1.5rem;
}

.profile-stat-count,
.profile-edit-btn {
  font-weight: 400;
}

/* Gallery Section */

.galleryPhotos {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem 2rem;
  padding-bottom: 3rem;
}

.gallery-item {
  position: relative;
  flex: 1 0 15rem;
  margin: 1rem;
  color: #fff;
  cursor: pointer;
}

.gallery-item:hover .gallery-item-info,
.gallery-item:focus .gallery-item-info {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.gallery-item-info {
  display: none;
}

.gallery-item-info li {
  display: inline-block;
  font-size: 1.7rem;
  font-weight: 600;
}

.gallery-item-likes {
  margin-right: 2.2rem;
}

.gallery-item-type {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2.5rem;
  text-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0px 0px 6px 2px rgba(117, 117, 117, 1);
}

/* Media Query */

@media screen and (max-width: 40rem) {
  .zoomedPhoto {
    width: 100% !important;
    margin-top: 30% !important;
    height: auto !important;
    object-fit: cover;
  }

  .profilePhotos {
    display: flex;
    flex-wrap: wrap;
    padding: 4rem 0;
  }

  .profilePhotos::after {
    display: none;
  }

  .profile-image,
  .profile-user-settings,
  .profile-bio,
  .profile-stats {
    float: none;
    width: auto;
  }

  .profile-image img {
    width: 7.7rem;
    height: 7.7rem;
  }

  .profile-user-settings {
    flex-basis: calc(100% - 10rem);
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  .profile-user-name {
    font-size: 1.6rem;
  }

  .profile-edit-btn {
    order: 1;
    padding: 0;
    text-align: center;
    margin-top: 1rem;
  }

  .profile-edit-btn {
    margin-left: 0;
  }

  .profile-bio {
    font-size: 1.4rem;
    margin-top: 1rem;
  }

  .profile-edit-btn,
  .profile-bio,
  .profile-stats {
    flex-basis: 100%;
  }

  .profile-stats {
    order: 1;
    margin-top: 1.5rem;
  }

  .profile-stats ul {
    display: flex;
    text-align: center;
    padding: 1.2rem 0;
    border-top: 0.1rem solid #dadada;
    border-bottom: 0.1rem solid #dadada;
  }

  .profile-stats li {
    font-size: 1.4rem;
    flex: 1;
    margin: 0;
  }

  .profile-stat-count {
    display: block;
  }
}

/* Spinner Animation */

@keyframes loader {
  to {
    transform: rotate(360deg);
  }
}

/*

The following code will only run if your browser supports CSS grid.

Remove or comment-out the code block below to see how the browser will fall-back to flexbox & floated styling. 

*/

@supports (display: grid) {
  .profilePhotos {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 3rem;
    align-items: center;
  }

  .profile-image {
    grid-row: 1 / -1;
  }

  .galleryPhotos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-gap: 2rem;
  }

  .profile-image,
  .profile-user-settings,
  .profile-stats,
  .profile-bio,
  .gallery-item,
  .gallery {
    width: auto;
    margin: 0;
  }

  @media (max-width: 40rem) {
    .profilePhotos {
      grid-template-columns: auto 1fr;
      grid-row-gap: 1.5rem;
    }

    .profile-image {
      grid-row: 1 / 2;
    }

    .profile-user-settings {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 1rem;
    }

    .profile-edit-btn,
    .profile-stats,
    .profile-bio {
      grid-column: 1 / -1;
    }

    .profile-user-settings,
    .profile-edit-btn,
    .profile-settings-btn,
    .profile-bio,
    .profile-stats {
      margin: 0;
    }
  }
}
.zoomedPhotoContainer {
  height: 80vh;
  text-align: center;
  padding: 10vh 10%;
}
.zoomedPhoto {
  height: 65vh;
  width: auto;
  object-fit: cover;
}

.prevBtn {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin: 0 10%;
}

.nextBtnContainer {
  margin-top: 2rem;
  width: 80%;
  display: inline-block;
  vertical-align: middle;
}

.dateLocationLbl {
  margin-top: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: rgb(119, 119, 119);
  display: block;
}
.profile-real-name {
  font-size: 1.2rem;
  color: rgb(119, 119, 119);
}
