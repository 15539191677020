.cards-container {
  margin: 5%;
  display: grid;
  grid-gap: 4rem;
  width: 90%;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 15rem);
}

.selectContainer {
  width: 100%;
  text-align: center;
}

.slectBtn {
  background-color: rgb(145, 148, 151);
  height: 3rem;
  width: 27%;
  border: 1px solid white;
  color: white;
}

.btnSelected {
  border-color: rgb(235, 156, 23);
  background-color: white;
  color: rgb(235, 156, 23);
  outline: none;
}

.homeContainer {
  margin-top: 2rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
