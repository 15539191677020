@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:400,400i,600,600i);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-container {
  height: 10vh;
  text-align: left;
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background-color: black;
}

.header-img {
  height: 70%;
  object-fit: contain;
  margin-left: 4rem;
  margin-top: 1rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html,
body {
  margin: 0;
  height: 100%;
  font-family: "Josefin Sans", sans-serif;
}
a {
  text-decoration: none;
}
.header {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  height: 100vh;
  min-width: 200px;
  color: #eee;
}
.header:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 30%,
    rgb(44, 44, 44) 100%
  );
}
.header:before {
  content: "";
  width: 100%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  backface-visibility: hidden;
  transform: scale(1);
  transform: translateZ(0);
  background: #363636 url(/background4.jpg) 50% 0 no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  -webkit-animation: grow 150s linear 10ms infinite;
          animation: grow 150s linear 10ms infinite;
  transition: all 0.4s ease-in-out;
  z-index: -2;
}
.header a {
  color: #eee;
}
.menu {
  display: block;
  width: 40px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 3px;
  position: absolute;
  right: 20px;
  top: 20px;
  text-decoration: none;
}
.menu:after {
  content: "";
  display: block;
  width: 20px;
  height: 3px;
  background: #fff;
  position: absolute;
  margin: 0 auto;
  top: 5px;
  left: 0;
  right: 0;
  box-shadow: 0 8px, 0 16px;
}
.logo {
  border: 2px solid #fff;
  border-radius: 3px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  align-content: center;
  margin: 20px;
  padding: 0px 10px;
  font-weight: 900;
  font-size: 1.1em;
  line-height: 1;
  box-sizing: border-box;
  height: 40px;
  cursor: pointer;
}
.sides,
.info {
  flex: 0 0 auto;
  width: 50%;
}
.info {
  margin-top: 5rem;
  width: 100%;
  padding: 15% 10% 0 10%;
  text-align: center;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}
.info h4,
.meta {
  font-size: 0.7em;
}
.meta {
  font-style: italic;
}
@-webkit-keyframes grow {
  0% {
    transform: scale(1) translateY(0px);
  }
  50% {
    transform: scale(1.2) translateY(-400px);
  }
}
@keyframes grow {
  0% {
    transform: scale(1) translateY(0px);
  }
  50% {
    transform: scale(1.2) translateY(-400px);
  }
}
.btn {
  color: #333;
  border: 2px solid;
  border-radius: 3px;
  text-decoration: none;
  display: inline-block;
  padding: 5px 10px;
  font-weight: 600;
}

.twtr {
  margin-top: 100px;
}
.btn.twtr:after {
  content: "\1F426";
  padding-left: 5px;
}

.introText {
  width: 80%;
  margin: 0 10%;
  word-break: break-word;
  font-size: 1.2rem;
}

.headerLogo {
  width: 3rem;
}

.logoContainer {
  text-align: center;
  height: 5rem;
  width: 100%;
  position: fixed;
  left: 0;
  top: 1rem;
}

.float {
  position: fixed;
  width: 3.5rem;
  height: 3.5rem;
  top: 20%;
  right: 0;
  background-color: rgb(235, 156, 23);
  border-radius: 50% 0 50% 50%;
  color: #fff;
  text-align: center;
  z-index: 99;
}

.buisnessCard {
  width: 2rem;
  height: 1.5rem;
  margin-top: 1rem;
}

.contactsPanel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: white;
  -webkit-animation-name: expandDiv;
          animation-name: expandDiv;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
}

@-webkit-keyframes expandDiv {
  from {
    left: 100%;
  }
  to {
    left: 0;
  }
}

@keyframes expandDiv {
  from {
    left: 100%;
  }
  to {
    left: 0;
  }
}

.contactsCard {
  height: 60vw;
  width: auto;
}

.closeContactsBtn {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: none;
  background-color: unset;
  color: rgb(235, 156, 23);
  font-size: 3rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.headerLogo2 {
  width: 2rem;
}

.logoContainer2 {
  text-align: center;
  height: 5rem;
  width: 100%;
  left: 0;
  top: 0;
}

.cards-container {
  margin: 5%;
  display: grid;
  grid-gap: 4rem;
  width: 90%;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 15rem);
}

.selectContainer {
  width: 100%;
  text-align: center;
}

.slectBtn {
  background-color: rgb(145, 148, 151);
  height: 3rem;
  width: 27%;
  border: 1px solid white;
  color: white;
}

.btnSelected {
  border-color: rgb(235, 156, 23);
  background-color: white;
  color: rgb(235, 156, 23);
  outline: none;
}

.homeContainer {
  margin-top: 2rem;
  margin-right: 2rem;
  margin-left: 2rem;
}

.card-container {
  color: #fff;
  font-size: 150%;
  height: 20rem;
  text-align: left;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 6px 2px rgba(117, 117, 117, 1);
}

.card-container:hover {
  transform: scale(1.1);
}

.card-img {
  width: 100%;
  height: 7rem;
  object-fit: cover;
}

.card-label-text {
  width: 90%;
  margin: 5%;
  height: 30%;
  display: inline-block;
  margin-bottom: 2rem;
}

.card-red {
  background-color: rgb(235, 156, 23);
}

.card-blue {
  background-color: rgb(235, 156, 23);
}

.card-yellow {
  background-color: rgb(235, 156, 23);
}

.card-loader {
  width: 100%;
  height: 7rem;
  object-fit: cover;
}
.card-label-date {
  height: 5%;
  text-align: right;
  width: 90%;
  margin: 0% 5%;
  display: inline-block;
  font-size: 1rem;
}

.bodyPhotos {
  font-family: "Open Sans", Arial, sans-serif;
  min-height: 100vh;
  color: #262626;
  font-size: 10px;
}

.containerPhotos {
  max-width: 100%;
  margin: 0 5%;
}

.btn {
  display: inline-block;
  font: inherit;
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  cursor: pointer;
}

.btn:focus {
  outline: 0.5rem auto #4d90fe;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/* Profile Section */

.profilePhotos {
  padding: 5rem 0;
}

.profilePhotos::after {
  content: "";
  display: block;
  clear: both;
}

.thumImg {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 0px 6px 2px rgba(117, 117, 117, 1);
}

.profile-image {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3rem;
}

.profile-user-settings,
.profile-stats,
.profile-bio {
  float: left;
  width: calc(66.666% - 2rem);
}

.profile-user-settings {
  margin-top: 1.1rem;
}

.profile-user-name {
  display: inline-block;
  font-size: 2.5rem;
  font-weight: 300;
}

.profile-edit-btn {
  font-size: 1.4rem;
  line-height: 1.8;
  border: 0.1rem solid #dbdbdb;
  border-radius: 0.3rem;
  padding: 0 2.4rem;
  margin-left: 2rem;
}

.profile-edit-btn2 {
  border: none;
  background-color: unset;
  color: white;
  font-size: 5rem;
  position: fixed;
  z-index: 99;
  top: 2rem;
  right: 2rem;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
}

.profile-bio {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 1.5rem;
}

.profile-stat-count,
.profile-edit-btn {
  font-weight: 400;
}

/* Gallery Section */

.galleryPhotos {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem 2rem;
  padding-bottom: 3rem;
}

.gallery-item {
  position: relative;
  flex: 1 0 15rem;
  margin: 1rem;
  color: #fff;
  cursor: pointer;
}

.gallery-item:hover .gallery-item-info,
.gallery-item:focus .gallery-item-info {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.gallery-item-info {
  display: none;
}

.gallery-item-info li {
  display: inline-block;
  font-size: 1.7rem;
  font-weight: 600;
}

.gallery-item-likes {
  margin-right: 2.2rem;
}

.gallery-item-type {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2.5rem;
  text-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0px 0px 6px 2px rgba(117, 117, 117, 1);
}

/* Media Query */

@media screen and (max-width: 40rem) {
  .zoomedPhoto {
    width: 100% !important;
    margin-top: 30% !important;
    height: auto !important;
    object-fit: cover;
  }

  .profilePhotos {
    display: flex;
    flex-wrap: wrap;
    padding: 4rem 0;
  }

  .profilePhotos::after {
    display: none;
  }

  .profile-image,
  .profile-user-settings,
  .profile-bio,
  .profile-stats {
    float: none;
    width: auto;
  }

  .profile-image img {
    width: 7.7rem;
    height: 7.7rem;
  }

  .profile-user-settings {
    flex-basis: calc(100% - 10rem);
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  .profile-user-name {
    font-size: 1.6rem;
  }

  .profile-edit-btn {
    order: 1;
    padding: 0;
    text-align: center;
    margin-top: 1rem;
  }

  .profile-edit-btn {
    margin-left: 0;
  }

  .profile-bio {
    font-size: 1.4rem;
    margin-top: 1rem;
  }

  .profile-edit-btn,
  .profile-bio,
  .profile-stats {
    flex-basis: 100%;
  }

  .profile-stats {
    order: 1;
    margin-top: 1.5rem;
  }

  .profile-stats ul {
    display: flex;
    text-align: center;
    padding: 1.2rem 0;
    border-top: 0.1rem solid #dadada;
    border-bottom: 0.1rem solid #dadada;
  }

  .profile-stats li {
    font-size: 1.4rem;
    flex: 1 1;
    margin: 0;
  }

  .profile-stat-count {
    display: block;
  }
}

/* Spinner Animation */

@-webkit-keyframes loader {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  to {
    transform: rotate(360deg);
  }
}

/*

The following code will only run if your browser supports CSS grid.

Remove or comment-out the code block below to see how the browser will fall-back to flexbox & floated styling. 

*/

@supports (display: grid) {
  .profilePhotos {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 3rem;
    align-items: center;
  }

  .profile-image {
    grid-row: 1 / -1;
  }

  .galleryPhotos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-gap: 2rem;
  }

  .profile-image,
  .profile-user-settings,
  .profile-stats,
  .profile-bio,
  .gallery-item,
  .gallery {
    width: auto;
    margin: 0;
  }

  @media (max-width: 40rem) {
    .profilePhotos {
      grid-template-columns: auto 1fr;
      grid-row-gap: 1.5rem;
    }

    .profile-image {
      grid-row: 1 / 2;
    }

    .profile-user-settings {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 1rem;
    }

    .profile-edit-btn,
    .profile-stats,
    .profile-bio {
      grid-column: 1 / -1;
    }

    .profile-user-settings,
    .profile-edit-btn,
    .profile-settings-btn,
    .profile-bio,
    .profile-stats {
      margin: 0;
    }
  }
}
.zoomedPhotoContainer {
  height: 80vh;
  text-align: center;
  padding: 10vh 10%;
}
.zoomedPhoto {
  height: 65vh;
  width: auto;
  object-fit: cover;
}

.prevBtn {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin: 0 10%;
}

.nextBtnContainer {
  margin-top: 2rem;
  width: 80%;
  display: inline-block;
  vertical-align: middle;
}

.dateLocationLbl {
  margin-top: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: rgb(119, 119, 119);
  display: block;
}
.profile-real-name {
  font-size: 1.2rem;
  color: rgb(119, 119, 119);
}

