.card-container {
  color: #fff;
  font-size: 150%;
  height: 20rem;
  text-align: left;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 6px 2px rgba(117, 117, 117, 1);
}

.card-container:hover {
  transform: scale(1.1);
}

.card-img {
  width: 100%;
  height: 7rem;
  object-fit: cover;
}

.card-label-text {
  width: 90%;
  margin: 5%;
  height: 30%;
  display: inline-block;
  margin-bottom: 2rem;
}

.card-red {
  background-color: rgb(235, 156, 23);
}

.card-blue {
  background-color: rgb(235, 156, 23);
}

.card-yellow {
  background-color: rgb(235, 156, 23);
}

.card-loader {
  width: 100%;
  height: 7rem;
  object-fit: cover;
}
.card-label-date {
  height: 5%;
  text-align: right;
  width: 90%;
  margin: 0% 5%;
  display: inline-block;
  font-size: 1rem;
}
