.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-container {
  height: 10vh;
  text-align: left;
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background-color: black;
}

.header-img {
  height: 70%;
  object-fit: contain;
  margin-left: 4rem;
  margin-top: 1rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:400,400i,600,600i");
html,
body {
  margin: 0;
  height: 100%;
  font-family: "Josefin Sans", sans-serif;
}
a {
  text-decoration: none;
}
.header {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  height: 100vh;
  min-width: 200px;
  color: #eee;
}
.header:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 30%,
    rgb(44, 44, 44) 100%
  );
}
.header:before {
  content: "";
  width: 100%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  backface-visibility: hidden;
  transform: scale(1);
  transform: translateZ(0);
  background: #363636 url(/background4.jpg) 50% 0 no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  animation: grow 150s linear 10ms infinite;
  transition: all 0.4s ease-in-out;
  z-index: -2;
}
.header a {
  color: #eee;
}
.menu {
  display: block;
  width: 40px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 3px;
  position: absolute;
  right: 20px;
  top: 20px;
  text-decoration: none;
}
.menu:after {
  content: "";
  display: block;
  width: 20px;
  height: 3px;
  background: #fff;
  position: absolute;
  margin: 0 auto;
  top: 5px;
  left: 0;
  right: 0;
  box-shadow: 0 8px, 0 16px;
}
.logo {
  border: 2px solid #fff;
  border-radius: 3px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  align-content: center;
  margin: 20px;
  padding: 0px 10px;
  font-weight: 900;
  font-size: 1.1em;
  line-height: 1;
  box-sizing: border-box;
  height: 40px;
  cursor: pointer;
}
.sides,
.info {
  flex: 0 0 auto;
  width: 50%;
}
.info {
  margin-top: 5rem;
  width: 100%;
  padding: 15% 10% 0 10%;
  text-align: center;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}
.info h4,
.meta {
  font-size: 0.7em;
}
.meta {
  font-style: italic;
}
@keyframes grow {
  0% {
    transform: scale(1) translateY(0px);
  }
  50% {
    transform: scale(1.2) translateY(-400px);
  }
}
.btn {
  color: #333;
  border: 2px solid;
  border-radius: 3px;
  text-decoration: none;
  display: inline-block;
  padding: 5px 10px;
  font-weight: 600;
}

.twtr {
  margin-top: 100px;
}
.btn.twtr:after {
  content: "\1F426";
  padding-left: 5px;
}

.introText {
  width: 80%;
  margin: 0 10%;
  word-break: break-word;
  font-size: 1.2rem;
}

.headerLogo {
  width: 3rem;
}

.logoContainer {
  text-align: center;
  height: 5rem;
  width: 100%;
  position: fixed;
  left: 0;
  top: 1rem;
}

.float {
  position: fixed;
  width: 3.5rem;
  height: 3.5rem;
  top: 20%;
  right: 0;
  background-color: rgb(235, 156, 23);
  border-radius: 50% 0 50% 50%;
  color: #fff;
  text-align: center;
  z-index: 99;
}

.buisnessCard {
  width: 2rem;
  height: 1.5rem;
  margin-top: 1rem;
}

.contactsPanel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: white;
  animation-name: expandDiv;
  animation-duration: 1s;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
}

@keyframes expandDiv {
  from {
    left: 100%;
  }
  to {
    left: 0;
  }
}

.contactsCard {
  height: 60vw;
  width: auto;
}

.closeContactsBtn {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: none;
  background-color: unset;
  color: rgb(235, 156, 23);
  font-size: 3rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.headerLogo2 {
  width: 2rem;
}

.logoContainer2 {
  text-align: center;
  height: 5rem;
  width: 100%;
  left: 0;
  top: 0;
}
